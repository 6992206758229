import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCTSDK } from 'services/ctClient'
import { Outlet } from 'react-router-dom'
import { useHasToken } from 'hooks/useHasToken'

const ctSDK = getCTSDK()

export const ShipperPortalRoute = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const validateToken = async () => {
            try {
                await ctSDK.post('/users/sp/validate')
            } catch (error) {
                console.error(error)
                navigate('/login')
            }
        }

        validateToken()
    }, [navigate])

    // If no token, to prevent runtime errors with fetching we will
    // redirect to login page immediately
    const hasToken = useHasToken()
    if (!hasToken) {
        navigate('/login')
        return null
    }

    return <Outlet />
}
