import styled from '@emotion/styled'
import Button from 'components/core/Button'
import TextInput from 'components/core/TextInput'
import { AnchorTag } from 'components/core/AnchorTag'
import Typography from 'components/core/Typography'
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constants/urls'
import { enterKeyPress } from 'utils/keyboard.utils'
import { pxToRem } from 'utils/rem.utils'
import Column from 'components/core/Column'

const emptyFunc = () => undefined

const StyledLoginFormPassword = styled.div`
    height: 100%;
    display: flex;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 3.6rem;
    padding-bottom: 2.4rem;

    .loginFormPassword__form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 2.4rem;
        margin: auto;
        max-width: ${pxToRem(500)};

        .loginFormPassword__form__text {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 1.2rem;
        }

        .loginFormPassword__form__inputs {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 1.6rem;
        }
    }
`

type LoginFormPasswordProps = {
    isSubmitting: boolean
    email: string
    password: string
    onLogin: () => void
    onChangeEmail: (value: string) => void
    onGoToRegister: () => void
    onChangePassword: (value: string) => void
}

export const LoginFormPassword = ({
    isSubmitting,
    email,
    password,
    onLogin = emptyFunc,
    onChangeEmail = emptyFunc,
    onGoToRegister = emptyFunc,
    onChangePassword = emptyFunc,
}: LoginFormPasswordProps) => {
    return (
        <StyledLoginFormPassword>
            <div className="loginFormPassword__form">
                <div className="loginFormPassword__form__text">
                    <Typography textAlign="center" color="primary" variant="h2">
                        Login
                    </Typography>
                    <Typography
                        textAlign="center"
                        color="secondary"
                        variant="body1"
                    >
                        Enter your credentials below to login
                    </Typography>
                </div>
                <div className="loginFormPassword__form__inputs">
                    <TextInput
                        value={email}
                        placeholder="name@example.com"
                        onChange={onChangeEmail}
                    />
                    <TextInput
                        isPassword
                        value={password}
                        placeholder="Password"
                        onChange={onChangePassword}
                        onKeyDown={enterKeyPress(onLogin)}
                    />
                    <Column gap="p1" fullWidth>
                        <Button
                            isDisabled={!email || !password}
                            isSubmitting={isSubmitting}
                            isFullWidth
                            variant="primary"
                            onClick={onLogin}
                        >
                            Continue
                        </Button>
                        <Button
                            isFullWidth
                            variant="secondary"
                            onClick={onGoToRegister}
                        >
                            Create an account
                        </Button>
                    </Column>
                </div>
                <div>
                    <Typography
                        textAlign="center"
                        color="secondary"
                        variant="body2"
                    >
                        By clicking continue, you agree to our{' '}
                        <AnchorTag
                            rel="noreferrer noopener"
                            href={TERMS_OF_USE_URL}
                            color="secondary"
                            target="_blank"
                        >
                            Terms of Use
                        </AnchorTag>{' '}
                        and{' '}
                        <AnchorTag
                            rel="noreferrer noopener"
                            href={PRIVACY_POLICY_URL}
                            color="secondary"
                            target="_blank"
                        >
                            Privacy Policy
                        </AnchorTag>
                        .
                    </Typography>
                </div>
            </div>
        </StyledLoginFormPassword>
    )
}
