import styled from '@emotion/styled'
import Column from 'components/core/Column'
import { SPACING } from 'constants/spacing.constants'
import { pxToRem } from 'utils/rem.utils'

export const LoginFormRoot = styled(Column)`
    height: 100%;
    justify-content: center;
    align-items: center;
    row-gap: ${SPACING.p3};
    margin: auto;
    max-width: ${pxToRem(500)};
`
