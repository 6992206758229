import styled from '@emotion/styled'
import Column from 'components/core/Column'
import Row from 'components/core/Row'
import { BASE_TOKENS, DESIGN_TOKENS } from 'constants/color.constants'
import { SPACING } from 'constants/spacing.constants'
import { Outlet } from 'react-router-dom'
import { getImageUrl } from 'utils/getImageUrl'

const StyledLoginLayoutMobile = styled(Row)`
    position: fixed;
    inset: 0;
    height: 100vh;
    height: 100dvh;

    .loginLayoutMobile__body {
        background-color: ${BASE_TOKENS.grey[900]};

        .loginLayoutMobile__body__logo {
            height: 2.4rem;
        }

        .loginLayoutMobile__body__text {
            color: ${BASE_TOKENS.grey[0]};
            font-family: Inter;
            font-size: 3rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
        }

        .loginLayoutMobile__body__card {
            border-radius: ${DESIGN_TOKENS.borderRadius.sm};
            margin: ${SPACING.p3};
            padding: ${SPACING.p3};
            background-color: ${BASE_TOKENS.grey[0]};
        }
    }
`

export const LoginLayoutMobile = () => {
    return (
        <StyledLoginLayoutMobile>
            <Column
                fullHeight
                fullWidth
                className="loginLayoutMobile__body"
                alignItems="center"
                justifyContent="center"
                gap="p2"
            >
                <Row alignItems="center" justifyContent="center" gap="sm">
                    <img
                        className="loginLayoutMobile__body__logo"
                        alt="Logo"
                        src={getImageUrl('shortLogoWhite.png')}
                    />
                    <p className="loginLayoutMobile__body__text">Cartage</p>
                </Row>
                <div className="loginLayoutMobile__body__card">
                    <Outlet />
                </div>
            </Column>
        </StyledLoginLayoutMobile>
    )
}
