import { assembleUrl } from './assembleUrl.utils'

export const URLS = {
    cartage: {
        home: () => assembleUrl(`/`, {}),
        logout: () => assembleUrl(`/logout`, {}),
        login: (args?: { email: string; loginToken: string }) =>
            assembleUrl('/login', args || {}),
        loginSuccess: (args?: { email?: string }) =>
            assembleUrl('/login/success', args || {}),
        order: (orderId: string) => assembleUrl(`/orders/${orderId}`, {}),
        customer: (customerId: string) =>
            assembleUrl(`/customers/${customerId}`, {}),
        partner: (partnerId: string) =>
            assembleUrl(`/partners/${partnerId}`, {}),
        wilson: {
            internal: (chatId: string) => assembleUrl(`/w/i/${chatId}`, {}),
            concierge: (customerId: string, chatId: string) =>
                assembleUrl(`/w/c/${customerId}/${chatId}`, {}),
            inbox: (customerId: string, chatId: string) =>
                assembleUrl(`/w/e/${customerId}/${chatId}`, {}),
        },
        scheduledTasks: () => assembleUrl(`/scheduledTasks`, {}),
        scheduledTaskDetails: (id: string) =>
            assembleUrl(`/scheduledTasks/${id}`, {}),
        shipperPortal: {
            shipments: () => assembleUrl('/sp/shipments', {}),
            shipment: (orderId: string) =>
                assembleUrl(`/sp/shipments/${orderId}`, {}),
            wilson: (chatId: string) => assembleUrl(`/sp/w/${chatId}`, {}),
            partner: (partnerId: string) =>
                assembleUrl(`/sp/partners/${partnerId}`, {}),
            addressBook: () => assembleUrl('/sp/addressBook', {}),
        },
        public: {
            agents: (searchParams: string) =>
                assembleUrl(`/agents?${searchParams}`, {}),
        },
        superAdmin: {
            home: () => assembleUrl('/admin', {}),
        },
        tracking: (trackingToken: string) =>
            assembleUrl('/tracking', { trackingToken }),
        lanes: {
            partnerLanes: (laneId: string) =>
                assembleUrl(`/lanes/${laneId}`, {}),
        },
        partnerLanes: {
            details: (partnerLaneId: string) =>
                assembleUrl(`/partnerLanes/${partnerLaneId}`, {}),
        },
        partnerLaneLogs: {
            details: (partnerLaneLogId: string) =>
                assembleUrl(`/partnerLaneLogs/${partnerLaneLogId}`, {}),
        },
    },
}
