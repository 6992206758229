import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import { MOBILE_BREAKPOINT } from 'constants/app.constants'

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(
        () => window.innerWidth <= MOBILE_BREAKPOINT
    )

    useEffect(() => {
        const handleResize = debounce(() => {
            setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT)
        }, 500)

        window.addEventListener('resize', handleResize)

        return () => {
            handleResize.cancel()
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return isMobile
}
