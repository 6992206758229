import styled from '@emotion/styled'
import { SPACING } from 'constants/spacing.constants'

export const Row = styled.div<{
    gap?: keyof typeof SPACING
    p?: keyof typeof SPACING
    pr?: keyof typeof SPACING
    pl?: keyof typeof SPACING
    m?: keyof typeof SPACING
    mb?: keyof typeof SPACING
    ml?: keyof typeof SPACING
    bgColor?: string
    alignItems?: React.CSSProperties['alignItems']
    justifyContent?: React.CSSProperties['justifyContent']
    flexWrap?: React.CSSProperties['flexWrap']
    fullWidth?: boolean
    fullHeight?: boolean
    maxWidthPct?: number
}>`
    display: flex;
    flex-direction: row;
    ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
    ${({ justifyContent }) =>
        justifyContent && `justify-content: ${justifyContent};`}
    ${({ gap }) => gap && `gap: ${SPACING[gap]};`}

    ${({ p }) =>
        // @NOTE: padding should come before pl and pr to allow for overrides.
        p && `padding: ${SPACING[p]};`}
    ${({ pl }) => pl && `padding-left: ${SPACING[pl]};`}
    ${({ pr }) => pr && `padding-right: ${SPACING[pr]};`}

    ${({ m }) => m && `margin: ${SPACING[m]};`}
    ${({ mb }) => mb && `margin-bottom: ${SPACING[mb]};`}
    ${({ ml }) => ml && `margin-left: ${SPACING[ml]};`}
    ${({ fullWidth }) => fullWidth && `width: 100%;`}
    ${({ fullHeight }) => fullHeight && `height: 100%;`}
    ${({ maxWidthPct }) => maxWidthPct && `max-width: ${maxWidthPct}%;`}
    ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}

    ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
`
