import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useLoginOld } from 'mutations/user.mutations'
import { showToastError } from 'hooks/useToast'
import { getCTSDK } from 'services/ctClient'
import { User } from 'types/user.types'
import { isCustomerUser, isSuperAdmin } from 'utils/user.utils'
import { URLS } from 'utils/url.utils'
import { TOKEN_KEY, TOKEN_REFRESH_KEY } from 'constants/app.constants'
import { LoginFormPassword } from './components/LoginFormPassword'

const ctSDK = getCTSDK()

const LoginFormPasswordContainer = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()
    const { isLoading: isSubmitting, mutateAsync: login } = useLoginOld()

    const _onGetUserAndNavigate = async () => {
        const meResp = await ctSDK.get<{ user: User }>('/users/me')
        const me = meResp.data?.user

        if (isSuperAdmin(me)) {
            navigate(URLS.cartage.superAdmin.home())
            return
        } else if (isCustomerUser(me)) {
            navigate(URLS.cartage.shipperPortal.wilson('general'))
            return
        }

        navigate(URLS.cartage.home())
    }

    useEffect(() => {
        const func = async () => {
            try {
                const token = Cookies.get(TOKEN_KEY)
                if (!token) return

                const resps = await Promise.allSettled([
                    ctSDK.post('/users/validate'),
                    ctSDK.post('/users/sp/validate'),
                ])
                if (resps.some((r) => r.status === 'fulfilled')) {
                    await _onGetUserAndNavigate()
                }
            } catch (error) {
                // do nothing
            }
        }

        func()
    }, [])

    const _onGoToRegister = () => {
        navigate('/register')
    }

    const _onLogin = async () => {
        try {
            const resp = await login({
                email,
                password,
            })

            Cookies.set(TOKEN_KEY, resp.data?.token)
            Cookies.set(TOKEN_REFRESH_KEY, resp.data?.refreshToken)

            const meResp = await ctSDK.get<{ user: User }>('/users/me')
            const me = meResp.data?.user

            if (isSuperAdmin(me)) {
                navigate('/admin')
                return
            } else if (isCustomerUser(me)) {
                navigate(URLS.cartage.shipperPortal.wilson('general'))
                return
            }

            navigate('/')
        } catch (error) {
            showToastError(error)
            console.error(error)
        }
    }

    return (
        <LoginFormPassword
            isSubmitting={isSubmitting}
            email={email}
            password={password}
            onLogin={_onLogin}
            onChangeEmail={setEmail}
            onGoToRegister={_onGoToRegister}
            onChangePassword={setPassword}
        />
    )
}

export default LoginFormPasswordContainer
