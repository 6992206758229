import { TOKEN_KEY } from 'constants/app.constants'
import Cookies from 'js-cookie'
import { useMemo } from 'react'

/**
 * Hook to check if the user has a token.
 */
export const useHasToken = () => {
    return useMemo(() => {
        return !!Cookies.get(TOKEN_KEY)
    }, [])
}
