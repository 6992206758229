export const NAV_HEIGHT = 72
export const MODULE_HEADER_HEIGHT = 48
export const TOKEN_KEY = 'token'
export const TOKEN_REFRESH_KEY = 'refreshToken'
export const SHIPPER_PORTAL_PREFIX = '/sp'
export const MOBILE_BREAKPOINT = 768
export const PRODUCTION_ORG_ID = 'imLOyTT16XB2vlPEnwSE'
export const GLOBAL_Z_INDEXES = {
    boards: {
        bodyRow: 1,
        bodyRowSticky: 2,
        headerRow: 3,
        headerRowSticky: 4,
    },
    mobileSlideOutPanel: 50,
    dialog: 100,
    fileDropzoneOverlay: 100,
}
