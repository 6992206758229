import styled from '@emotion/styled'
import { SPACING } from 'constants/spacing.constants'

export const Column = styled.div<{
    fullHeight?: boolean
    fullWidth?: boolean
    gap?: keyof typeof SPACING
    p?: keyof typeof SPACING
    pr?: keyof typeof SPACING
    pl?: keyof typeof SPACING
    alignItems?: React.CSSProperties['alignItems']
    justifyContent?: React.CSSProperties['justifyContent']
}>`
    display: flex;
    flex-direction: column;
    ${({ gap }) => gap && `gap: ${SPACING[gap]};`}

    ${({ p }) =>
        // @NOTE: padding should come before pl and pr to allow for overrides.
        p && `padding: ${SPACING[p]};`}
    ${({ pl }) => pl && `padding-left: ${SPACING[pl]};`}
    ${({ pr }) => pr && `padding-right: ${SPACING[pr]};`}

    ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
    ${({ fullHeight }) => fullHeight && `height: 100%;`}
    ${({ fullWidth }) => fullWidth && `width: 100%;`}
    ${({ justifyContent }) =>
        justifyContent && `justify-content: ${justifyContent};`}
`
